import * as React from 'react';
import { Headline, Paragraph, Title } from '@bojagi/pablo/Typography';
import { Button } from '@bojagi/pablo/Button';
import styled, { css } from 'styled-components';
import { breakpoint, getColor } from '@bojagi/pablo/styleHelpers';
import { Tooltip } from '@bojagi/pablo';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { SimpleText } from '../sections/SimpleText';

const IntroBlock = styled.a`
  border-radius: 10px;
  padding: 1.3em;
  margin-bottom: 2em;
  color: #000;
  background-color: ${getColor('brand', 'light')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  flex-direction: column;

  :hover {
    text-decoration: none;
  }

  ${breakpoint(
    'sm',
    css`
      flex-direction: row;
    `
  )}
`;

const IntroBlockText = styled(Title)`
  margin-bottom: 0.5em;

  ${breakpoint(
    'sm',
    css`
      margin-bottom: 0;
    `
  )}
`;

const IntroBlockButton = styled(Button).attrs({ size: 'large' })`
  :hover {
    text-decoration: none;
  }
`;

const PricingGrid = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  flex-direction: column;
  margin: 0 -1em;

  ${breakpoint(
    'sm',
    css`
      flex-direction: row;
      margin: 0 -1em 5em;
    `
  )}
`;

const PricingGridItem = styled.div`
  flex-basis: 33.3333333%;
  box-sizing: border-box;
  flex-grow: 1;
  flex-shrink: 0;
`;

const PricingGridItemContent = styled.div`
  background-color: #eeeff6;
  padding: 1.75em 1em 1em;
  min-height: 240px;
  overflow: hidden;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1em 1em;

  ${breakpoint(
    'sm',
    css`
      margin: 0 1em;
    `
  )}
`;

const PricingGridItemAdditinalInfo = styled.div`
  font-size: 0.7em;
  text-align: center;
  margin: 0.5em 0 0;
  font-weight: normal;
`;

const PricingGridItemList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;
`;

const PricingGridItemListItem = styled.li`
  margin: 0 0 0.75em;
  line-height: 1.3em;
  padding: 0;
`;

const DisabledButton = styled(Button)`
  opacity: 0.5;
`;

const PricingPage = () => {
  return (
    <Layout showLogo>
      <Seo title="Pricing" />
      <SimpleText>
        <Headline>Pricing</Headline>
        <IntroBlock href="/register">
          <IntroBlockText>Bojagi is free during Beta</IntroBlockText>
          <IntroBlockButton href="/register">Register for the open beta</IntroBlockButton>
        </IntroBlock>
        <PricingGrid>
          <PricingGridItem>
            <PricingGridItemContent>
              <Title>
                During open Beta{' '}
                <span role="img" aria-label="artist">
                  👩‍🎤
                </span>
              </Title>
              <PricingGridItemList>
                <PricingGridItemListItem>Unlimited React components</PricingGridItemListItem>
                <PricingGridItemListItem>Unlimited users</PricingGridItemListItem>
                <PricingGridItemListItem>
                  Unlimited public and private GitHub repositories
                </PricingGridItemListItem>
                <PricingGridItemListItem>Warm fuzzy feelings :)</PricingGridItemListItem>
              </PricingGridItemList>
              <Button as="a" variant="secondary" href="/register">
                Free
              </Button>
            </PricingGridItemContent>
          </PricingGridItem>
          <PricingGridItem>
            <PricingGridItemContent>
              <Title>
                Open Source{' '}
                <span role="img" aria-label="love">
                  ❤
                </span>
                ️
              </Title>
              <PricingGridItemList>
                <PricingGridItemListItem>
                  Free forever for open source projects (public repositories)
                </PricingGridItemListItem>
                <PricingGridItemListItem>Unlimited React components</PricingGridItemListItem>
                <PricingGridItemListItem>Unlimited users</PricingGridItemListItem>
              </PricingGridItemList>
              <Button as="a" variant="secondary" href="/register">
                Free
              </Button>
            </PricingGridItemContent>
          </PricingGridItem>
          <PricingGridItem>
            <PricingGridItemContent>
              <Title>
                After beta{' '}
                <span role="img" aria-label="businessman">
                  👨‍💼
                </span>
              </Title>
              <PricingGridItemList>
                <PricingGridItemListItem>1 private repository</PricingGridItemListItem>
                <PricingGridItemListItem>Unlimited public repositories</PricingGridItemListItem>
                <PricingGridItemListItem>Unlimited React components</PricingGridItemListItem>
                <PricingGridItemListItem>Unlimited users</PricingGridItemListItem>
              </PricingGridItemList>
              <Tooltip
                content={
                  <>
                    Not yet! Try our free accounts{' '}
                    <span role="img" aria-label="smiley">
                      🙂
                    </span>
                  </>
                }
              >
                <DisabledButton variant="secondary">
                  <span>$60</span>
                  <PricingGridItemAdditinalInfo as="span">/month</PricingGridItemAdditinalInfo>
                  <PricingGridItemAdditinalInfo>
                    + $10 for additional repositories
                  </PricingGridItemAdditinalInfo>
                </DisabledButton>
              </Tooltip>
            </PricingGridItemContent>
          </PricingGridItem>
        </PricingGrid>
        <Paragraph>
          Do you have custom needs? Contact us via{' '}
          <a href="mailto:hello@bojagi.io">hello@bojagi.io</a>!
        </Paragraph>
      </SimpleText>
    </Layout>
  );
};

export default PricingPage;
